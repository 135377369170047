/* eslint-disable react/function-component-definition*/
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import styles from '../../pages/styles/techPages.module.scss';
import helping from '../../images/technology/vac/helping-content-providers.png';
import testing from '../../images/technology/vac/testing-wherever-you-need-it.jpg';
import checking from '../../images/technology/vac/checking-video-playback-quality.jpg';
import intended from '../../images/technology/vac/checking-the-app-works-as-intended.jpg';

export default function MobileTabCardsVAC({ id }) {
    const { t } = useTranslation();
    const [ cardData, setCardData ] = useState(0);

    const cardsContent = [
        {
            id: 0,
            image: helping,
            title: t('siteMetadata.vodAssetChecking.helping'),
            text: t('siteMetadata.vodAssetChecking.helpingText'),
            alt: 'Helping content providers'
        },
        {
            id: 1,
            image: testing,
            title: t('siteMetadata.vodAssetChecking.testing'),
            text: t('siteMetadata.vodAssetChecking.testingText'),
            alt: 'Testing wherever you need it'
        },
        {
            id: 2,
            image: checking,
            title: t('siteMetadata.vodAssetChecking.checking'),
            text: t('siteMetadata.vodAssetChecking.checkingText'),
            alt: 'Checking video playback quality'
        },
        {
            id: 3,
            image: intended,
            title: t('siteMetadata.vodAssetChecking.intended'),
            text: t('siteMetadata.vodAssetChecking.intendedText'),
            alt: 'Checking the app works as intended'
        }
    ];

    const handleClick = (name, e) => {
        setCardData(name);
    };

    return (
        <section id={styles.interactiveSection}>
            <h2 id={styles.interactiveHeader}>
                {t('siteMetadata.vodAssetChecking.leave')}
            </h2>
            <div className={styles.interactiveSectionWrapper}>
                <ul id={styles.interactiveList}>
                    {
                        cardsContent.map(card => (
                            <li
                                key={card.id}
                                className={styles.interactiveItem}
                                onClick={(e) => handleClick(card.id, e)}
                            >
                                <div className={styles.chevronButton}>
                                    <FontAwesomeIcon
                                        icon={cardData === card.id ? faChevronDown : faChevronRight}
                                    />
                                </div>
                                <div className={styles.cardText}>
                                    <h3 className={styles.cardTitle}>
                                        {card.title}
                                    </h3>
                                    <p className={styles.cardParagraph}>
                                        <p className={cardData === card.id ? styles.cardVisible : styles.cardInvisible}>
                                            {card.text}
                                        </p>
                                    </p>
                                </div>
                                <div className={styles.interactiveImage}>
                                    <img src={card.image} className={cardData === card.id ? styles.imageVisible : styles.imageInvisible} alt={card.alt} />
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    );
}